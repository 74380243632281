import React from "react";
import "../styles/Footer.css";
import instagramLogo from "../Assets/Instagram-Logo.png";
import youtubeLogo from "../Assets/youtube_logo.svg";
import facebookLogo from "../Assets/facebook_logo.svg";
import whatsappLogo from "../Assets/whatsapp-logo.svg";
import logo from "../Assets/logo.PNG";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer-section">
      <div className="container footer-container">
        {/* Top Row: Logo and Links */}
        <div className="row align-items-center">
          {/* Logo Section */}
          <div className="col-md-3 d-flex text-center text-md-start mb-3 mb-md-0">
            <div>
              <img src={logo} alt="AgriQuiz Logo" className="footer-logo" />
              <p className="tagline">Let's prepare Precisely</p>
            </div>
            {window.innerWidth <= 500 && (
              <div className="col-md-3 text-center text-md-end mt-4">
                <a
                  href="https://www.instagram.com/agriquiz.live?igsh=MXNpMTY4eWd5emtqMA=="
                  className="social-link"
                >
                  <img
                    src={instagramLogo}
                    alt="Instagram"
                    className="social-icon"
                  />
                </a>
                <a href="#" className="social-link">
                  <img
                    src={youtubeLogo}
                    alt="YouTube"
                    className="social-icon"
                  />
                </a>
                <a href="#" className="social-link">
                  <img
                    src={facebookLogo}
                    alt="Facebook"
                    className="social-icon"
                  />
                </a>
                <a href="#" className="social-link">
                  <img
                    src={whatsappLogo}
                    alt="WhatsApp"
                    className="social-icon"
                  />
                </a>
              </div>
            )}
          </div>

          {/* Links Section */}
          <div className="col-md-6">
            <div className="row">
              <div className="col-6 footer-links">
                <ul>
                  <li>Tutorial Videos</li>
                  <li>Test Series</li>
                  <li>Questions</li>
                  <Link to="/blog" className="nav-link fs-7 text-white">
                    <li>Blogs</li>
                  </Link>
                </ul>
              </div>
              <div className="col-6 footer-links">
                <ul>
                  <Link to="/about" className="nav-link fs-7 text-white">
                    <li>About Us</li>
                  </Link>
                  {/* <li>News</li> */}
                  <Link to="/contact" className="nav-link fs-7 text-white">
                    <li>Career</li>
                  </Link>
                  <Link to="/contact" className="nav-link fs-7 text-white">
                    <li>Contact Us</li>
                  </Link>
                  <Link to="/privacy-policy" className="nav-link fs-7 text-white">
                    <li>Privacy Policy</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          {/* Social Media Icons */}
          {window.innerWidth > 500 && (
            <div className="col-md-3 text-center text-md-end">
              <a
                href="https://www.instagram.com/agriquiz.live?igsh=MXNpMTY4eWd5emtqMA=="
                className="social-link"
              >
                <img
                  src={instagramLogo}
                  alt="Instagram"
                  className="social-icon"
                />
              </a>
              <a href="#" className="social-link">
                <img src={youtubeLogo} alt="YouTube" className="social-icon" />
              </a>
              <a href="#" className="social-link">
                <img
                  src={facebookLogo}
                  alt="Facebook"
                  className="social-icon"
                />
              </a>
              <a href="#" className="social-link">
                <img
                  src={whatsappLogo}
                  alt="WhatsApp"
                  className="social-icon"
                />
              </a>
            </div>
          )}
        </div>

        {/* Bottom Row: Copyright */}
        <div className="row">
          <div className="col-12 text-center mt-3">
            <span className="footer-copyright">
              Copyright &#169; 2024 AgriQuiz. All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
