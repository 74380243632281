import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import API from "../../Helpers/Api";
import { Helmet } from "react-helmet";
import Skeleton from "react-skeleton-loader";

function BlogDetails() {
  const [allBlogs, setAllBlogs] = useState([]);
  const [blogDetails, setBlogDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Access location object
  const leftSectionRef = useRef(null); // Ref for scrolling the left section

  // Function to fetch the blog details by ID
  const getBlogDetail = async (slug) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API.getUrls.getBlogs}/${slug}`);

      setBlogDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog details:", error);
    }
  };

  // Function to fetch all blogs list
  const getAllBlogsList = async () => {
    try {
      const response = await axios.get(API.getUrls.getBlogs);
      setAllBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching all blogs:", error);
    }
  };

  // Fetch blog details and scroll to top when ID changes
  useEffect(() => {
    setLoading(true);
    const slug = window.location.pathname.split("/")[2];
    if (slug) {
      getBlogDetail(slug);
    }

    // Scroll the left section to top
    if (leftSectionRef.current) {
      leftSectionRef.current.scrollTo(0, 0);
    }

    window.scrollTo(0, 0); // Scroll to top of the page
  }, [location.state?.id]); // Add location.state?.id as a dependency

  useEffect(() => {
    // Fetch all blogs list only once
    getAllBlogsList();
  }, []);

  // Strip HTML tags from the content
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // Handle blog click to update content
  const handleBlogClick = (id) => {
    getBlogDetail(id);
  };

  return (
    <div className="container my-5">
      <Helmet>
        <title>{blogDetails.blogs_title}</title>
        <meta
          name="description"
          content={stripHtmlTags(blogDetails?.blog_content)}
        />
        <meta
          property="og:title"
          content={blogDetails.blogs_title || "Default Open Graph Title"}
        />
        {blogDetails.blog_type === "video" ? (
          <meta
            property="og:video"
            content={`https://core.agriquiz.live${blogDetails.blogs_poster}`}
          />
        ) : (
          <meta
            property="og:image"
            content={`https://core.agriquiz.live${blogDetails.blogs_poster}`}
          />
        )}

        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <h2 className="card-title mb-3" style={{ paddingTop: "3rem" }}>
        {blogDetails.blogs_title}
      </h2>

      <div className="row fw-bold position-relative">
        {/* Left Scrollable Section */}
        <div
          className="col-md-7 mb-4 scrollable-left"
          ref={leftSectionRef} // Attach the ref here
          style={{ height: "700px", overflowY: "auto" }}
        >
          {loading ? (
            <Skeleton count={5} />
          ) : (
            <div className="card h-100 border-0">
              {blogDetails.blog_type === "video" ? (
                <video
                  src={"https://core.agriquiz.live" + blogDetails.blogs_poster}
                  controls
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "300px",
                  }}
                ></video>
              ) : (
                <img
                  className="card-img-top"
                  src={"https://core.agriquiz.live" + blogDetails.blogs_poster}
                  alt="Main Blog"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "300px",
                  }}
                />
              )}
              <div className="card-body p-0 mt-3">
                <div
                  dangerouslySetInnerHTML={{ __html: blogDetails.blog_content }}
                />
              </div>
            </div>
          )}
        </div>

        {/* Right Fixed Section */}
        <div className="col-md-5">
          {allBlogs.map((item) => (
            <Link
              to={`/blog-details/${item?.slug}`}
              className="nav-link fs-7 text-white"
              state={{ id: item?._id }} // Pass the blog ID to the link
              key={item._id}
              onClick={() => handleBlogClick(item._id)} // Handle click event to update content
            >
              <div
                className="card mb-3 border-0 d-flex align-items-center"
                style={{ flexDirection: "row" }}
              >
                {item.blog_type === "video" ? (
                  <video
                    src={"https://core.agriquiz.live" + item.blogs_poster}
                    controls
                    style={{
                      objectFit: "contain",
                      width: "40%",
                      height: "100px",
                    }}
                  ></video>
                ) : (
                  <img
                    className="card-img"
                    src={"https://core.agriquiz.live" + item.blogs_poster}
                    alt="Related Blog"
                    style={{ width: "40%", objectFit: "contain" }}
                  />
                )}
                <div className="card-body p-2">
                  <p
                    className="card-text mb-0"
                    style={{ fontSize: "0.9rem", textAlign: "left" }}
                  >
                    {item.blogs_title}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
