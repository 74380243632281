import React from 'react';


function VideoSection() {
  return (
    <section className="video-section py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Video Section */}
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <div className="video-wrapper">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/69d7cfOodSE?si=4A5gKI2d7Us0mEzt"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          {/* Text Section */}
          <div className="col-lg-6 col-md-12">
            <div className="watch-video-text">
              <h3 className="mb-3">Watch Videos</h3>
              <p>
                Utilize the video and text blogs section in our app to clarify your concepts. We produce curated
                videos focusing on challenging topics across various subjects, helping you grasp the concepts
                and the underlying logic more effectively.
              </p>
              <p>
                Our engaging content is designed to simplify complex ideas and enhance your understanding. You can
                scroll through our blogs for deeper insights into different topics, allowing you to expand your
                knowledge further.
              </p>
              <p>
                With AgriQuiz, you'll have the resources you need to master difficult concepts and excel in your studies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VideoSection;
