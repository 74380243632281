import React, { useEffect, useState, useRef } from "react";
import Welcome_video from "../Assets/Welcome_video.mp4";
import mobile_welcome from "../Assets/mobile_welcome.mp4";
import "../styles/Hero.css";
import axios from "axios";
import API from "../Helpers/Api";

function HeroSection() {
  const videoRef = useRef(null);

  useEffect(() => {
    // Attempt to play the video on component mount
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        // Handle playback error (like autoplay restrictions)
        console.log("Video playback was prevented:", error);
      });
    }
  }, []);

  return (
    <section className="hero-section py-3">
      <div className="container">
        <div className="row justify-content-center">
          {/* Adjusting the grid to ensure it takes 80% width or more */}
          <div className="col-12 col-md-10 col-lg-12">
            {window.innerWidth > 500 ? (
              <div className="video-container hero-video">
                <video
                  className="videos w-100"
                  src={Welcome_video}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
            ) : (
              <video
                ref={videoRef}
                className="videos w-100"
                src={mobile_welcome}
                autoPlay
                loop
                controls
                playsInline
                muted // Enable muted for autoplay to work on most browsers
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
