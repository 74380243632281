import React from "react";
import card_jcb from '../Assets/blog_jcb.png';
import 'bootstrap/dist/css/bootstrap.min.css';

function BlogCards() {
    return (
        <div className="container mt-5 pb-5">
            <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col">
                    <div className="card h-100 border-0 text-white">
                        <img src={card_jcb} className="card-img" alt="blogs_img" />
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100 border-0 text-white">
                        <img src={card_jcb} className="card-img" alt="blogs_img" />
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100 border-0 text-white">
                        <img src={card_jcb} className="card-img" alt="blogs_img" />
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100 border-0 text-white">
                        <img src={card_jcb} className="card-img" alt="blogs_img" />
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100 border-0 text-white">
                        <img src={card_jcb} className="card-img" alt="blogs_img" />
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100 border-0 text-white">
                        <img src={card_jcb} className="card-img" alt="blogs_img" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogCards;
