import React from 'react';
import Img from "../Assets/test_combine.svg";

function TestSeries() {
  return (
    <section className="test-section py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Section */}
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <img src={Img} alt="Test Series" className="img-fluid rounded" />
          </div>

          {/* Text Section */}
          <div className="col-lg-6 col-md-12">
            <h3 className="fw-bold mb-4">Test Series</h3>
            <p>
              AgriQuiz offers a comprehensive selection of test series designed to help you evaluate your learning in a
              competitive and supportive environment. Our platform focuses on preparing students for various competitive
              exams in the agriculture field, catering to both higher education and job opportunities.
            </p>
            <p>
              With a diverse range of topics covered, students can effectively assess their knowledge and identify areas
              for improvement. In line with our vision for the future, we aim to introduce an on-demand test series feature,
              allowing students to request specific topics, curated to meet your needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestSeries;
