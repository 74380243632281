import React, { useState, useEffect } from "react";
import axios from "axios";
import API from "../Helpers/Api";
import { Link, useLocation } from "react-router-dom";

function BlogLatest() {
  const [allBlogs, setAllBlogs] = useState([]);

  const getAllBlogsList = async () => {
    try {
      const response = await axios.get(API.getUrls.getBlogs);
      setAllBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBlogsList();
  }, []);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const containText = (text, maxLength = 250) => {
    if (text?.length > maxLength) {
      return (
        <>
          {text.slice(0, maxLength)}
          <span style={{ color: "blue" }}> ...Read more</span>
        </>
      );
    }
    return text;
  };

  return (
    <div className="container my-5">
      <h4 className="text-secondary border-bottom border-secondary pb-2 mb-4">
        Latest Blogs
      </h4>

      <div className="row fw-bold position-relative">
        {/* Left Card Section */}
        <div
          className="col-md-7 mb-4 scrollable-left"
          style={{ height: "500px", overflowY: "auto" }}
        >
          {allBlogs?.length > 0 &&
            allBlogs.map((item) => (
              <Link
                to={`/blog-details/${item.slug}`}
                className="nav-link fs-7 text-white"
                state={{id: item?._id}}
                key={item._id} // Use the blog's ID as the key
              >
                <div className="card h-100 border-0">
                  {item.blog_type === "video" ? (
                    <video
                      src={`https://core.agriquiz.live${item.blogs_poster}`}
                      controls
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        height: "300px",
                      }}
                    ></video>
                  ) : (
                    <img
                      className="card-img-top"
                      src={`https://core.agriquiz.live${item.blogs_poster}`}
                      alt="Main Blog"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        height: "300px",
                      }}
                    />
                  )}
                  <div className="card-body p-0 mt-3">
                    <h5 className="card-title mb-3">{item.blogs_title}</h5>
                    <p className="card-text">
                      {containText(stripHtmlTags(item.blog_content))}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
        </div>

        {/* Right Card Section */}
        <div
          className="col-md-5"
          style={{
            position: "sticky",
            top: "0",
            alignSelf: "flex-start",
          }}
        >
          {allBlogs?.length > 0 &&
            allBlogs.map((item) => (
              <Link
                to={`/blog-details/${item.slug}`}
                className="nav-link fs-7 text-white"
                state={{id: item?._id}}
                key={`right-${item._id}`} // Unique key for right cards
              >
                <div
                  className="card mb-3 border-0 d-flex align-items-center"
                  style={{ flexDirection: "row" }}
                >
                  {item.blog_type === "video" ? (
                    <video
                      src={`https://core.agriquiz.live${item.blogs_poster}`}
                      controls
                      style={{
                        objectFit: "contain",
                        width: "40%",
                        height: "100px",
                      }}
                    ></video>
                  ) : (
                    <img
                      className="card-img"
                      src={`https://core.agriquiz.live${item.blogs_poster}`}
                      alt="Related Blog"
                      style={{ width: "40%", objectFit: "contain" }}
                    />
                  )}
                  <div className="card-body p-2">
                    <p
                      className="card-text mb-0"
                      style={{ fontSize: "0.9rem", textAlign: "left" }}
                    >
                      {item.blogs_title}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}

export default BlogLatest;
