import React from 'react';
import Profile from "../Assets/profile2.jpeg";
import "../styles/Feedback.css";

function Feedback() {
  return (
    <section className="feedback-section py-5">
      <div className="container">
        <h3 className="feedback-title mb-5">Feedback</h3>
        <div className="row">
          {/* Feedback Item 1 */}
          <div className="col-lg-3 col-md-6 mb-4 rounded">
            <div className="feedback-box text-center p-4">
              <div className="img mb-3">
                <img src={Profile} alt="Profile" className="rounded-circle img-fluid" />
              </div>
              <h4 className="mb-2">John Doe</h4>
              <p className="feedback-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis.
              </p>
              <div className="stars">
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </div>
            </div>
          </div>

          {/* Feedback Item 2 */}
          <div className="col-lg-3 col-md-6 mb-4 rounded">
            <div className="feedback-box text-center p-4">
              <div className="img mb-3">
                <img src={Profile} alt="Profile" className="rounded-circle img-fluid" />
              </div>
              <h4 className="mb-2">Jane Smith</h4>
              <p className="feedback-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis.
              </p>
              <div className="stars">
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </div>
            </div>
          </div>

          {/* Feedback Item 3 */}
          <div className="col-lg-3 col-md-6 mb-4 rounded">
            <div className="feedback-box text-center p-4">
              <div className="img mb-3">
                <img src={Profile} alt="Profile" className="rounded-circle img-fluid" />
              </div>
              <h4 className="mb-2">Mike Johnson</h4>
              <p className="feedback-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis.
              </p>
              <div className="stars">
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </div>
            </div>
          </div>

          {/* Feedback Item 4 */}
          <div className="col-lg-3 col-md-6 mb-4 rounded">
            <div className="feedback-box text-center p-4">
              <div className="img mb-3">
                <img src={Profile} alt="Profile" className="rounded-circle img-fluid" />
              </div>
              <h4 className="mb-2">Emily Davis</h4>
              <p className="feedback-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis.
              </p>
              <div className="stars">
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Feedback;
