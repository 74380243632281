import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import AboutHero from '../Components/AboutHero'
import OurTeam from '../Components/OurTeam'


function AboutUs() {
  return (
    <div>
      <AboutHero />
      <OurTeam />
    </div>
  )
}

export default AboutUs
