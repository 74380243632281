import React from "react";
import shubham from "../Assets/shubham2.jpg";
import Priyansh from "../Assets/Priyansh.png";
import akansha from "../Assets/akansha.png";
import khushi from "../Assets/khushi1.jpg";
import "bootstrap/dist/css/bootstrap.min.css";

function OurTeam() {
  return (
    <div className="container ourteam-section mt-5 mb-5">
      <h2 className="mb-5">Our Team</h2>

      <div className="row align-items-center mb-5">
        <div className="col-md-4 text-center">
          <img
            src={shubham}
            alt="Shubham Muneshwar"
            className="img-fluid rounded-circle"
            width={300}
          />
        </div>
        <div className="col-md-8">
          <h4>Shubham Muneshwar</h4>
          <h5 className="text-muted">
            Founder, Agriculturist, Tech-Enthusiast, Senior Developer
          </h5>
          <p>
            As an agriculture graduate and a tech enthusiast, I’ve experienced
            firsthand the challenges students face when preparing for exams and
            job opportunities. The lack of focused platforms for effective
            practice is a common hurdle. That’s why I created AgriQuiz, a
            platform built to address this gap and offer a targeted solution for
            exam preparation.
          </p>
          <p>
            With over 20,000 MCQs covering critical agricultural topics,
            AgriQuiz allows students to practice efficiently and build the
            knowledge needed for success. Additionally, we provide video
            lectures to simplify complex topics and help clarify key concepts.
          </p>
          <p>
            At AgriQuiz, we’re committed to taking agriculture education to the
            next level, blending technology and learning to empower students on
            their path to success.
          </p>
        </div>
      </div>

      <div className="row align-items-center mb-5">
        <div className="col-md-8">
          <h4>Khushi Bahekar</h4>
          <h5 className="text-muted">BCA SRU Raipur, Developer-Designer</h5>
          <p>
            As a developer with a background in BCA (Bachelor of Computer
            Applications), I designed AgriQuiz to blend my technical expertise
            with the needs of agriculture students preparing for competitive
            exams. Drawing on my own experience with the challenges of exam
            preparation, I aimed to create a user-friendly, effective platform.
            AgriQuiz offers a vast collection of MCQs covering all critical
            agricultural topics, alongside video lectures to help clarify
            difficult concepts. By integrating technology with education,
            AgriQuiz provides students with the comprehensive tools they need to
            succeed in both academic and professional pursuits.
          </p>
        </div>
        <div className="col-md-4 text-center">
          <img
            src={khushi}
            alt="Khushi Bahekar"
            className="img-fluid rounded-circle"
            width={300}
          />
        </div>
      </div>

      <div className="row align-items-center mb-5">
        <div className="col-md-4 text-center">
          <img
            src={akansha}
            alt="Akanksha Swarnkar"
            className="img-fluid rounded-circle"
            width={300}
          />
        </div>
        <div className="col-md-8">
          <h4>Akanksha Swarnkar</h4>
          <h5 className="text-muted">
            Dept. of Microbiology (IGKV-Raipur) - Social Media Manager @Agriquiz
          </h5>
          <p>
            Agriculture is related to our day-to-day life. Learning
            agriculture-related concepts would be so easy if we learned by
            scrolling. How? Now you'll be surprised to know about our page,
            AGRIQUIZ. AGRIQUIZ helps you learn by scrolling in a very effective
            way through images, videos, and quizzes. AGRIQUIZ enables you to
            learn visually by using infographics, videos, and visual notes in
            our posts to help reinforce what you're learning.
          </p>
          <p>
            We use various hashtags like #agriculture to find relevant topics
            and inspiration. AGRIQUIZ focuses on your subject area, study tips,
            or motivational content. Many educators and students share
            helpful resources.
          </p>
        </div>
      </div>

      <div className="row align-items-center mb-5 mt-3">
        <div className="col-md-8">
          <h4>Priyansh Shukla</h4>
          <h5 className="text-muted">
            Junior Frontend Developer & Agri-Tech Enthusiast
          </h5>
          <p>
            I am a third-year Agriculture student at IGKV, Raipur, with a
            mission to transform the agriculture landscape through cutting-edge
            tech solutions. With certifications from Harvard's CS50x and Google,
            I am passionate about bridging the digital divide in agriculture by
            designing intuitive, user-centric Agri-Tech solutions that empower
            farmers and promote sustainable farming practices for a greener
            tomorrow.
          </p>
          <p>
            Education and technology are powerful tools for change. As a
            contributor to AgriQuiz, I am dedicated to using digital solutions
            to inspire and educate the next generation of agriculture leaders.
            Let’s work together to cultivate a sustainable future for
            agriculture.
          </p>
        </div>
        <div className="col-md-4 text-center">
          <img
            src={Priyansh}
            alt="Priyansh Shukla"
            className="img-fluid rounded-circle"
            width={300}
          />
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
