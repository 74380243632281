import React from 'react'
import Header from "../Components/Header"
import HeroSection from '../Components/HeroSection'
import CountingSection from '../Components/CountingSection'
import TestSeries from '../Components/TestSeries'
import QuestionSection from '../Components/QuestionSection'
import LeaderBoardSection from '../Components/LeaderBoardSection'
import VideoSection from '../Components/VideoSection'
import Feedback from '../Components/Feedback'
import ContactUs from '../Components/ContactUs'
import Footer from '../Components/Footer'

function HomePage() {
  return (
    
    <div className='scroll'>
      {/* <Header /> */}
      <main style ={{marginTop: '10vh' }}>
        <HeroSection className='scroll_effect'/>
        <CountingSection className='scroll_effect'/>
        <TestSeries className='scroll_effect'/>
        <QuestionSection className='scroll_effect'/>
        <LeaderBoardSection className='scroll_effect'/>
        <VideoSection className='scroll_effect'/>
        <Feedback className='scroll_effect'/>
        <ContactUs className='scroll_effect'/>
        {/* <Footer className='scroll_effect'/> */}
      </main>
    </div>
  )
}

export default HomePage
