import React from 'react';
import Profile1 from "../Assets/profile1.jpg";
import Profile2 from "../Assets/profile2.jpeg";
import "../styles/LeaderBoard.css"

function LeaderBoardSection() {
  return (
    <section className="leader-section py-5">
      <div className="container text-center">
        <h2 className="mb-5">LEADERBOARD</h2>
        <div className="row justify-content-center align-items-end">
          {/* Second Place */}
          <div className="col-md-4 col-sm-12 position mb-4 mb-md-0">
            <h4 className="text-muted">2nd</h4>
            <div className="leader-img mb-3">
              <img src={Profile2} alt="Second Place Profile" className="img-fluid rounded-circle" />
            </div>
            <p className="fw-bold">John Doe</p>
          </div>

          {/* First Place - Center */}
          <div className="col-md-4 col-sm-12 position first mb-4 mb-md-0 ">
            <h4 className="text-warning">1st</h4>
            <div className="leader-img mb-3">
              <img src={Profile1} alt="First Place Profile" className="img-fluid rounded-circle first-place-img" />
            </div>
            <p className="fw-bold">Jane Smith</p>
          </div>

          {/* Third Place */}
          <div className="col-md-4 col-sm-12 position mb-4 mb-md-0 ">
            <h4 className="text-muted">3rd</h4>
            <div className="leader-img mb-3">
              <img src={Profile2} alt="Third Place Profile" className="img-fluid rounded-circle" />
            </div>
            <p className="fw-bold">John Doe</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeaderBoardSection;
