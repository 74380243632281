import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Blog_hero from '../Components/Blog_hero'
import Blog_latest from '../Components/Blog_latest'
import Blog_cards from '../Components/Blog_cards'


function Blog() {
  return (
    <div>
        {/* <Header/> */}
        <main style ={{marginTop: '10vh', paddingTop: '5vh' }}>
            <Blog_hero />
            <Blog_latest />
            <Blog_cards />
        </main>
        
    </div>
  )
}

export default Blog
