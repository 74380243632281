import React, { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is installed
import map from "../Assets/map.png";
import "../styles/ContactUs.css";
import API from "../Helpers/Api";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); // State for loading status
  const [responseMessage, setResponseMessage] = useState(""); // For success or error message

  // Handle input change dynamically
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (
      !formData.name ||
      !formData.email ||
      !formData.mobile ||
      !formData.message
    ) {
      setResponseMessage("All fields are required.");
      return;
    }

    setLoading(true);
    setResponseMessage(""); // Reset previous messages

    try {
      const response = await axios.post(API.postUrls.postWebMessage, formData);
      setResponseMessage(
        "Thank you for contacting us. We'll get back to you soon!"
      );
      setFormData({ name: "", email: "", mobile: "", message: "" }); // Reset form fields
      window.location.assign("/blog");
    } catch (error) {
      // console.error("Error submitting form:", error);
      setResponseMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contactus-section py-5">
      <div className="container">
        <h2 className="mb-5">Contact</h2>
        <div className="contactus-wrapper">
          {/* Map Section */}
          <div className="map-section">
            <img src={map} alt="Map" className="img-fluid" />
          </div>

          {/* Contact Form Section */}
          <div className="contactus-form">
            <div className="form">
              <h2 className="form-title mb-4 text-white">Contact Us</h2>

              <form onSubmit={handleSubmit}>
                {/* Name */}
                <div className="form-box mb-3">
                  <label htmlFor="name" className="form-label text-white">
                    Name
                  </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    id="name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                {/* Email */}
                <div className="form-box mb-3">
                  <label htmlFor="email" className="form-label text-white">
                    Email
                  </label>
                  <br />
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    id="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                {/* Mobile No. */}
                <div className="form-box mb-3">
                  <label htmlFor="number" className="form-label text-white">
                    Mobile No.
                  </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter your number"
                    name="mobile"
                    id="mobile"
                    className="form-control"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>

                {/* Message */}
                <div className="form-box mb-3">
                  <label htmlFor="message" className="form-label text-white">
                    Message
                  </label>
                  <br />
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Enter your message"
                    rows="2"
                    className="form-control"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>

                {/* Submit button */}
                <button
                  type="submit"
                  className="submit-btn btn btn-success"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>

              {/* Response message */}
              {responseMessage && (
                <p
                  className={`mt-3 ${
                    responseMessage.includes("error")
                      ? "text-danger"
                      : "text-success"
                  }`}
                >
                  {responseMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
