const mainDomain = "https://core.agriquiz.live/";
const imgDomain = "https://core.agriquiz.live";

// const mainDomain = "http://localhost:5001/";
// const imgDomain = "http://localhost:5001";

const API = {
  imgDomain,
  getUrls: {
    getBlogs: mainDomain + "blogs/list-web",
  },
  postUrls: {
    postWebMessage: mainDomain + "web-contact/post-message",
  },
  deleteUrls: {},
};

export default API;
