import HomePage from "./Pages/HomePage";
// import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import "react-loading-skeleton/dist/skeleton.css";

import "bootstrap/dist/css/bootstrap.min.css";
import BlogDetails from "./Components/blogs/BlogDetail";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import PrivacyPolicy from "./Components/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details/:id" element={<BlogDetails />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
