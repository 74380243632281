import React from 'react';
import { Link } from "react-router-dom";
import logo from "../Assets/logo.PNG";
import '../styles/header.css';

function Header() {
  return (
    <header>
      <nav className="navbar navbar-expand-lg fixed-top custom-navbar">
        <div className="container-fluid">
          {/* Logo */}
          <Link className="navbar-brand ms-lg-5 px-4" to="/">
            <img src={logo} alt="Logo" style={{ height: '60px', marginLeft: '20px' ,display: 'block' }} />
          </Link>

          {/* Toggle button for mobile view */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto  me-lg-5">
              <li className="nav-item">
                <Link className="nav-link fs-7 text-white" to="/about">About Us</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link fs-7 text-white" to="/news">News</Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link fs-7 text-white" to="/blog">Blogs</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-7 text-white" to="/contact">Career</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-7 text-white" to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
