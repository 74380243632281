import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container my-5 py-5">
      <h1 className="text-center mb-4">Privacy Policy for AgriQuiz</h1>
      <p>
        <strong>Effective Date:</strong> 27/11/2024
      </p>

      <section className="mb-5">
        <p>
          At <strong>AgriQuiz</strong>, accessible from{" "}
          <a
            href="https://agriquiz.live"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://agriquiz.live
          </a>
          , protecting your privacy is one of our top priorities. This Privacy
          Policy explains how we collect, use, and safeguard your information
          when you use our website and services.
        </p>
        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us at{" "}
          <a href="mailto:[Insert Contact Email]">info@agriquiz.live</a>.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">1. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul className="list-group mb-3">
          <li className="list-group-item">
            <strong>Personal Information:</strong> Includes your name, email
            address, phone number, and educational details when you register for
            an account, subscribe to newsletters, or interact with our platform.
          </li>
          <li className="list-group-item">
            <strong>Non-Personal Information:</strong> Browser type, operating
            system, IP address, device type, pages accessed, and referral links
            to help us analyze website performance and improve user experiences.
          </li>
          <li className="list-group-item">
            <strong>Payment Information:</strong> Details such as transaction ID
            and payment status are securely handled by third-party payment
            processors. AgriQuiz does not store your credit/debit card
            information.
          </li>
        </ul>
        <p>
          We may also collect information you provide voluntarily, such as
          feedback, support inquiries, or data shared during user surveys.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">2. How We Use Your Information</h2>
        <p>
          Your data helps us deliver a better experience. Specifically, we use
          your information to:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            Provide access to quizzes, test series, video lectures, and other
            educational resources on AgriQuiz.
          </li>
          <li className="list-group-item">
            Customize user experiences, such as suggesting relevant content or
            quizzes based on your profile and activity.
          </li>
          <li className="list-group-item">
            Send notifications about updates, new features, special offers, or
            technical alerts related to the platform.
          </li>
          <li className="list-group-item">
            Manage subscriptions, process payments, and ensure billing accuracy.
          </li>
          <li className="list-group-item">
            Analyze usage patterns and feedback to improve AgriQuiz and develop
            new services.
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">3. How We Protect Your Information</h2>
        <p>
          At AgriQuiz, we implement robust security measures to safeguard your
          personal data, including:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            Data encryption during transmission (e.g., SSL/TLS protocols) to
            prevent unauthorized access.
          </li>
          <li className="list-group-item">
            Regular monitoring of our systems to detect and mitigate potential
            security threats.
          </li>
          <li className="list-group-item">
            Restricting access to personal data to authorized personnel only.
          </li>
        </ul>
        <p>
          However, please note that no method of online transmission or storage
          is 100% secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">4. Sharing Your Information</h2>
        <p>
          We respect your privacy and will never sell or rent your personal
          information. We may share your information in limited cases:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            <strong>Service Providers:</strong> With trusted partners for tasks
            like hosting, payment processing, and analytics, strictly under data
            protection agreements.
          </li>
          <li className="list-group-item">
            <strong>Legal Requirements:</strong> To comply with applicable laws,
            regulations, or legal processes, such as responding to subpoenas or
            court orders.
          </li>
          <li className="list-group-item">
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of our assets, user data may be transferred to
            the new entity.
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">5. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and tracking technologies to enhance your experience.
          Cookies help us remember your preferences, personalize content, and
          analyze site performance. You can disable cookies through your browser
          settings, but some site features may not function correctly.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">6. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites or services. We
          are not responsible for the privacy practices, terms, or content of
          these external sites. We encourage you to review their policies before
          interacting with them.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">7. Children's Privacy</h2>
        <p>
          AgriQuiz does not knowingly collect personal information from
          individuals under the age of 13. If we learn that we have
          inadvertently collected such information, we will promptly delete it.
          Parents or guardians can contact us at{" "}
          <a href="mailto:[Insert Contact Email]">info@agriquiz.live</a> for
          assistance.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">8. Your Privacy Rights</h2>
        <p>
          Depending on your location, you may have rights under applicable data
          protection laws, including:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            Requesting access to the personal data we hold about you.
          </li>
          <li className="list-group-item">
            Updating or correcting inaccurate information.
          </li>
          <li className="list-group-item">
            Requesting data deletion under certain circumstances.
          </li>
          <li className="list-group-item">
            Opting out of marketing communications.
          </li>
        </ul>
        <p>
          To exercise your rights, contact us at{" "}
          <a href="mailto:[Insert Contact Email]"></a>info@agriquiz.live
        </p>
      </section>

      <section className="mb-4">
        <h2 className="mb-3">9. Changes to This Privacy Policy</h2>
        <p>
          AgriQuiz reserves the right to update this Privacy Policy at any time.
          We will notify you of significant changes by posting the updated
          policy on this page, along with the updated effective date.
        </p>
      </section>

      <section>
        <h2 className="mb-3">10. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us:
          <br />
          <strong>AgriQuiz</strong>
          <br />
          Email: <a href="mailto:[Insert Contact Email]">info@agriquiz.live</a>
          <br />
          Website: <a href="https://agriquiz.live">https://agriquiz.live</a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
