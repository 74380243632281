import React from 'react';
import QImg1 from "../Assets/question_combine.svg";

function QuestionSection() {
  return (
    <section className="question-section py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Text Section */}
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <h3 className="fw-bold mb-4">Question Bank</h3>
            <p>
              The questions provided in our app are meticulously curated and selected to enhance your knowledge, 
              featuring over 20,000+ questions across all subjects. We offer a variety of question sets categorized 
              by subject and topic, allowing you to focus on specific areas of study.
            </p>
            <p>
              Each question set is designed with different difficulty levels to challenge and improve your understanding. 
              Additionally, every set must be completed within a specified time limit, simulating the experience of an actual 
              exam hall. This structure not only helps you manage your time effectively but also prepares you for the pressures 
              of real exam conditions.
            </p>
            <p>
              With AgriQuiz, you’ll gain confidence and competence as you work through our extensive question bank, paving the way 
              for your success.
            </p>
          </div>

          {/* Image Section */}
          <div className="col-lg-6 col-md-12 d-flex justify-content-center">
            <img src={QImg1} alt="Question Bank" className="img-fluid rounded" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default QuestionSection;
