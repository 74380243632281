import React, { useEffect, useState } from 'react';

function CountingSection() {
  const [counts, setCounts] = useState({
    questions: 0,
    testSeries: 0,
    blogs: 0,
  });

  useEffect(() => {
    const animateCounts = () => {
      const duration = 5000; // Animation duration in ms
      const frameRate = 1000 / 60; // Frame rate for smooth animation
      const totalFrames = duration / frameRate;

      const easeOutQuad = (t) => t * (2 - t); // Easing function for smoothness

      const increment = {
        questions: 20000 / totalFrames,
        testSeries: 50 / totalFrames,
        blogs: 20 / totalFrames,
      };

      let frame = 0;

      const interval = setInterval(() => {
        frame++;

        setCounts((prevCounts) => ({
          questions: Math.min(20000, Math.floor(easeOutQuad(frame / totalFrames) * 20000)),
          testSeries: Math.min(50, Math.floor(easeOutQuad(frame / totalFrames) * 50)),
          blogs: Math.min(20, Math.floor(easeOutQuad(frame / totalFrames) * 20)),
        }));

        if (frame >= totalFrames) {
          clearInterval(interval);
        }
      }, frameRate);
    };

    animateCounts();
  }, []);

  return (
    <section className="counting-section py-5 bg-light">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="box p-4 bg-white shadow">
              <h3 className="display-4 fw-bold">{counts.questions.toLocaleString()}+</h3>
              <p className="lead">Questions</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="box p-4 bg-info text-white shadow">
              <h3 className="display-4 fw-bold">{counts.testSeries}+</h3>
              <p className="lead">Test Series</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="box p-4 bg-primary text-white shadow">
              <h3 className="display-4 fw-bold">{counts.blogs}+</h3>
              <p className="lead">Blogs</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CountingSection;
