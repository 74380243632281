import React from "react";
import card1 from '../Assets/blog_card1.png';
import card2 from '../Assets/blog_card2.png';
import card3 from '../Assets/blog_card3.png';
import '../styles/blog_hero.css';

function Blog_hero() {
    return (
        <div className="bg-hero">
            <div className="container">   
            {/* heading */}
            <div className="hero_heading text-center text-white mb-5">
                <h1 className="pt-5">Blogs on Agriculture,</h1>
                <h1>Experience & Knowledge</h1>
            </div>
          
            <div className="row justify-content-center pt-3 pb-5">
                {/* Card 1 */}
                <div className="col-12 col-md-4 mb-4">
                    <div className="card h-100">
                        <img src={card1} className="card-img-top img-fluid box-shadow" alt="card 1" />
                    </div>
                </div>

                {/* Card 2 */}
                <div className="col-12 col-md-4 mb-4">
                    <div className="card h-100">
                        <img src={card2} className="card-img-top img-fluid box-shadow" alt="card 2" />
                    </div>
                </div>

                {/* Card 3 */}
                <div className="col-12 col-md-4 mb-4">
                    <div className="card h-100">
                        <img src={card3} className="card-img-top img-fluid box-shadow" alt="card 3" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Blog_hero;
